export const VALID_GUESSES = [
  'addison',
  'willink',
  'macleod',
  'hancock',
  'lansley',
  'burnham',
  'johnson',
  'milburn',
  'dorrell'
]
