import React, { useState, useEffect } from 'react'
import { Cell } from './grid/Cell'

export const Intro = () => {

    const alphabet = "JMNTSDQGILVWPZIUXFO"


    const [letters, setLetters] = useState([])
    useEffect(() => {
        const interval = setInterval(() => {
            const randomCharacter = alphabet[letters.length]
            setLetters([...letters, randomCharacter])
        }, 200);
        return () => clearInterval(interval);
    }, [letters]);

    return (
        <div className="-mr-2">
            <div className='flex justify-end mb-1 '>
                <Cell status="absent" isCompleted={letters.includes('T')} isRevealing={letters.includes('T')} value="T" />
                <Cell status="absent" isCompleted={letters.includes('S')} isRevealing={letters.includes('S')} value="S" />
                <Cell isCompleted={letters.includes('D')} isRevealing={letters.includes('D')} status={letters.includes('D') ? 'correct' : undefined} value={letters.includes('D') ? 'T' : 'D'} />
                <Cell isCompleted={letters.includes('I')} isRevealing={letters.includes('I')} status={letters.includes('I') ? 'correct' : undefined} value={letters.includes('I') ? 'A' : 'I'} />
                <Cell isCompleted={letters.includes('O')} isRevealing={letters.includes('O')} status={letters.includes('O') ? 'correct' : undefined} value={letters.includes('O') ? 'K' : 'O'} />
                <Cell isCompleted={letters.includes('N')} isRevealing={letters.includes('N')} status={letters.includes('N') ? 'correct' : undefined} value={letters.includes('N') ? 'E' : 'N'} />

            </div>
            <div className='flex justify-end mb-1 pr-[5.5rem]'>
                <Cell status="absent" isCompleted={letters.includes('Q')} isRevealing={letters.includes('Q')} value="Q" />
                <Cell status="absent" isCompleted={letters.includes('W')} isRevealing={letters.includes('W')} value="W" />
                <Cell status="absent" isCompleted={letters.includes('T')} isRevealing={letters.includes('T')} value="T" />

                <Cell isCompleted={letters.includes('J')} isRevealing={letters.includes('J')} status={letters.includes('J') ? 'correct' : undefined} value={letters.includes('J') ? 'T' : 'J'} />
                <Cell isCompleted={letters.includes('L')} isRevealing={letters.includes('L')} status={letters.includes('L') ? 'correct' : undefined} value={letters.includes('H') ? 'T' : 'H'} />
                <Cell isCompleted={letters.includes('V')} isRevealing={letters.includes('V')} status={letters.includes('V') ? 'correct' : undefined} value={letters.includes('E') ? 'T' : 'E'} />
            </div>
            <div className='flex justify-end mb-1 '>
                <Cell status="absent" isCompleted={letters.includes('P')} isRevealing={letters.includes('P')} value="P" />
                <Cell status="absent" isCompleted={letters.includes('Z')} isRevealing={letters.includes('Z')} value="Z" />
                <Cell isCompleted={letters.includes('G')} isRevealing={letters.includes('G')} status={letters.includes('G') ? 'correct' : undefined} value={letters.includes('G') ? 'T' : 'G'} />
                <Cell isCompleted={letters.includes('U')} isRevealing={letters.includes('U')} status={letters.includes('U') ? 'correct' : undefined} value={letters.includes('U') ? 'E' : 'U'} />
                <Cell isCompleted={letters.includes('I')} isRevealing={letters.includes('I')} status={letters.includes('I') ? 'correct' : undefined} value={letters.includes('I') ? 'S' : 'I'} />
                <Cell isCompleted={letters.includes('J')} isRevealing={letters.includes('J')} status={letters.includes('J') ? 'correct' : undefined} value={letters.includes('J') ? 'T' : 'J'} />

            </div>
            <div className='flex justify-end mb-1 pr-11'>
                <Cell isCompleted={letters.includes('T')} isRevealing={letters.includes('T')} status="absent" value="T" />
                <Cell isCompleted={letters.includes('D')} isRevealing={letters.includes('D')} status="absent" value="D" />
                <Cell isCompleted={letters.includes('F')} isRevealing={letters.includes('F')} status="absent" value="F" />
                <Cell isCompleted={letters.includes('S')} isRevealing={letters.includes('S')} status="absent" value="S" />
                <Cell isCompleted={letters.includes('J')} isRevealing={letters.includes('J')} value="J" status="absent" />
                <Cell status="absent" isCompleted={letters.includes('V')} isRevealing={letters.includes('V')} value="V" />

            </div>
        </div>
    )
}
