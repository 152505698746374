import { CharStatus } from '../../lib/statuses'
import classnames from 'classnames'
import { REVEAL_TIME_MS } from '../../constants/settings'
import { isThrowStatement } from 'typescript'

type Props = {
  value?: string
  status?: CharStatus
  isRevealing?: boolean
  isCompleted?: boolean
  position?: number,
  isHome?: boolean
}

export const Cell = ({
  value,
  status,
  isRevealing,
  isCompleted,
  position = 0,
  isHome = false
}: Props) => {
  const isFilled = value && !isCompleted
  const shouldReveal = isRevealing && isCompleted
  const animationDelay = `${position * REVEAL_TIME_MS}ms`


  const classes = classnames(
    'w-10 h-12 border-solid border-2 flex items-center justify-center mx-0.5 text-4xl font-black mb-2 ',
    {
      'home': isHome,
      'border-white border-solid border-2 text-white bg-opacity-30 border-opacity-30':
        !status,
      'bg-transparent':
        !value,
      'absent border-white  text-white bg-opacity-30 border-opacity-30':
        status === 'absent',
      'correct bg-green-500 border-solid border-2 text-white border-green-500':
        status === 'correct',
      'present bg-yellow-500 border-solid border-2 text-white border-yellow-500':
        status === 'present',
      'cell-fill-animation': isFilled,
      'cell-reveal': shouldReveal,
    }
  )

  return (
    <div className={classes} style={{ animationDelay }} data-status={status}>
      <div className="letter-container" style={{ animationDelay }}>
        {value}
      </div>
    </div>
  )
}
