import React from 'react';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";

import './App.css';
import Order from './Order';
import Wordle from './Worlde';
import { AlertProvider } from './Worlde/context/AlertContext';
import { Intro } from './Worlde/components/Intro';
import { Carousel } from './Carousel';
function App() {
  return (
    <AlertProvider>

      <div className="h-screen flex flex-col w-full overflow-hidden">
        <div className="p-4 flex-1 w-full max-w-md mx-auto relative z-20">
          <Router>
            <Routes>
              <Route path="/wordle" element={<Wordle />} />
              <Route path="/wordle-ad" element={<Advert />} />
              <Route path="/order-ad" element={<OrderAdvert />} />
              <Route path="/order" element={<Order />} />
              <Route path="/" element={<Wordle />} />
            </Routes>
          </Router>
        </div>
        <footer className='p-4'>
          <img src="/globe.png" alt="Hanover" className="pointer-none h-128 w-auto fixed bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 opacity-50" />
          <img src="/logo.png" alt="Hanover" className="h-6" />
        </footer>
      </div>
    </AlertProvider>

  )
}

function Advert() {
  return <div className="fixed top-8 right-8"><Intro /></div>
}

function OrderAdvert() {
  return <div className="fixed left-0 top-8 w-full transform advert-wrapper"><Carousel /></div>
}


function Home() {
  return <nav>
    <ul className="grid grid-flow-row">
      <li className="btn">
        <Link to="/wordle">Word Game</Link>
      </li>
      <li className="btn">
        <Link to="/order">Order Game</Link>
      </li>
    </ul>
  </nav>
}
export default App;
