import { useState, useEffect } from 'react'
import { Grid } from './components/grid/Grid'
import { Keyboard } from './components/keyboard/Keyboard'
import { InfoModal } from './components/modals/InfoModal'
import { StatsModal } from './components/modals/StatsModal'
import { BaseModal } from './components/modals/BaseModal'
import { AlertProvider } from './context/AlertContext'
import { Intro } from './components/Intro'
import {
  WIN_MESSAGES,
  GAME_COPIED_MESSAGE,
  NOT_ENOUGH_LETTERS_MESSAGE,
  WORD_NOT_FOUND_MESSAGE,
  CORRECT_WORD_MESSAGE,
  DISCOURAGE_INAPP_BROWSER_TEXT,
} from './constants/strings'
import {
  MAX_CHALLENGES,
  REVEAL_TIME_MS,
  WELCOME_INFO_MODAL_MS,
  DISCOURAGE_INAPP_BROWSERS,
} from './constants/settings'
import {
  isWordInWordList,
  isWinningWord,
  solution,
  unicodeLength,
} from './lib/words'
import { addStatsForCompletedGame, loadStats } from './lib/stats'
import {
  loadGameStateFromLocalStorage,
  saveGameStateToLocalStorage,
} from './lib/localStorage'
import { default as GraphemeSplitter } from 'grapheme-splitter'

import './App.css'
import { AlertContainer } from './components/alerts/AlertContainer'
import { useAlert } from './context/AlertContext'
import { Navbar } from './components/navbar/Navbar'
import { isInAppBrowser } from './lib/browser'

function Wordle() {


  const { showError: showErrorAlert, showSuccess: showSuccessAlert } =
    useAlert()


  const [currentGuess, setCurrentGuess] = useState('')
  const [isGameWon, setIsGameWon] = useState(false)
  const [isWelcomed, setIsWelcomed] = useState(false)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false)
  const [currentRowClass, setCurrentRowClass] = useState('')
  const [isGameLost, setIsGameLost] = useState(false)

  const [isRevealing, setIsRevealing] = useState(false)
  const [guesses, setGuesses] = useState([])

  const [stats, setStats] = useState(() => loadStats())





  useEffect(() => {
    // if no game state on load,
    // show the user the how-to info modal


    if (!loadGameStateFromLocalStorage()) {
      setTimeout(() => {
        setIsInfoModalOpen(true)
      }, WELCOME_INFO_MODAL_MS)
    }
  })

  useEffect(() => {
    DISCOURAGE_INAPP_BROWSERS &&
      isInAppBrowser() &&
      showErrorAlert(DISCOURAGE_INAPP_BROWSER_TEXT, {
        persist: false,
        durationMs: 7000,
      })
  }, [showErrorAlert])


  const clearCurrentRowClass = () => {
    setCurrentRowClass('')
  }

  useEffect(() => {
    saveGameStateToLocalStorage({ guesses, solution })
  }, [guesses])

  useEffect(() => {
    if (isGameWon) {
      const winMessage =
        WIN_MESSAGES[Math.floor(Math.random() * WIN_MESSAGES.length)]
      const delayMs = REVEAL_TIME_MS * solution.length;

      setTimeout(() => {
        setIsStatsModalOpen(true)
        setIsGameWon(true);
      }, delayMs)

    }

    if (isGameLost) {
      setTimeout(() => {
        setIsStatsModalOpen(true)
        setIsGameLost(true);
      }, (solution.length + 1) * REVEAL_TIME_MS)
    }
  }, [isGameWon, isGameLost, showSuccessAlert])

  const onChar = (value) => {
    if (
      unicodeLength(`${currentGuess}${value}`) <= solution.length &&
      guesses.length < MAX_CHALLENGES &&
      !isGameWon
    ) {
      setCurrentGuess(`${currentGuess}${value}`)
    }
  }

  const onDelete = () => {
    setCurrentGuess(
      new GraphemeSplitter().splitGraphemes(currentGuess).slice(0, -1).join('')
    )
  }

  const onEnter = () => {
    if (isGameWon || isGameLost) {
      return
    }

    if (!(unicodeLength(currentGuess) === solution.length)) {
      setCurrentRowClass('jiggle')
      return showErrorAlert(NOT_ENOUGH_LETTERS_MESSAGE, {
        onClose: clearCurrentRowClass,
      })
    }

    if (!isWordInWordList(currentGuess)) {
      setCurrentRowClass('jiggle')
      return showErrorAlert(WORD_NOT_FOUND_MESSAGE, {
        onClose: clearCurrentRowClass,
      })
    }



    setIsRevealing(true)
    // turn this back off after all
    // chars have been revealed
    const delayMs = REVEAL_TIME_MS * solution.length;


    setTimeout(() => {
      setIsRevealing(false)
    }, delayMs)

    const winningWord = isWinningWord(currentGuess)

    if (
      unicodeLength(currentGuess) === solution.length &&
      guesses.length < MAX_CHALLENGES &&
      !isGameWon
    ) {
      setGuesses([...guesses, currentGuess])
      setCurrentGuess('')


      if (winningWord) {
        setStats(addStatsForCompletedGame(stats, guesses.length))


        setTimeout(() => {
          return setIsGameWon(true)

        }, delayMs + REVEAL_TIME_MS * 2)


      }

      if (guesses.length === MAX_CHALLENGES - 1) {

        setTimeout(() => {
          return setIsGameLost(true)

        }, delayMs + REVEAL_TIME_MS * 2)
      }
    }
  }



  if (!isWelcomed) {
    return (
      <div>
        <Intro />
        <h3 className="mt-16 font-black text-xl uppercase text-white  pb-4">We’re looking for smart, curious people that think differently. <br />We call it <em>UNCOMMON SENSE</em>.
          <br /><span className="text-pink">Take our test to see if you can work with the best.</span></h3>
        <p>
          <button onClick={() => setIsWelcomed(true)} className="btn">Take the test</button>
        </p>
      </div >
    )
  }


  if (isGameWon) {
    return (
      <div>
        <img src="https://c.tenor.com/PG4KoxyPydAAAAAd/matt-hancock-smile.gif" alt="" className="w-full mb-4" />
        <h3 className="font-black text-3xl uppercase text-white border-b border-white border-opacity-50 pb-4">Congratulations</h3>
        <p className="font-serif text-white mb-4 text-2xl pt-4">You clearly take your health policy as seriously as we do.<br />
          <br />
          Find out more about what we might have in common on our careers page.</p>
        <p>
          <a target="_blank" href="https://www.hanovercomms.com/vacancies/" className="btn">Careers with Hanover</a>
        </p>
      </div>
    )
  }

  if (isGameLost) {
    return (
      <div>
        <img src="https://i.giphy.com/W1s6lGLKDIDquw8VD7.gif" alt="" className="w-full mb-4" />
        <h3 className="font-black text-3xl uppercase text-white border-b border-white border-opacity-50 pb-4">Good effort</h3>
        <p className="font-serif text-white mb-4 text-2xl pt-4">But on this occasion Matt Hancock eluded you.<br />
          <br />
          Click the link below to see our current roles.</p>
        <p>
          <a target="_blank" href="https://www.hanovercomms.com/vacancies/" className="btn">Careers with Hanover</a>
        </p>
      </div>
    )
  }

  return (
    <div className=" flex flex-col">

      <div className="md:max-w-7xl w-full mx-auto flex flex-col grow">
        <p className="font-serif text-white mb-4 text-xl pt-4">Think you know your health policy? Guess the name of the former UK Secretary of State for Health in just five tries. </p>
        <div className="mb-6 grow border-b border-white border-opacity-30 mb-8 border-dashed">
          <Grid
            solution={solution}
            guesses={guesses}
            currentGuess={currentGuess}
            isRevealing={isRevealing}
            currentRowClassName={currentRowClass}
          />
        </div>
        <Keyboard
          onChar={onChar}
          onDelete={onDelete}
          onEnter={onEnter}
          solution={solution}
          guesses={guesses}
          isRevealing={isRevealing}
        />
        <InfoModal
          isOpen={isInfoModalOpen}
          handleClose={() => setIsInfoModalOpen(false)}
        />
        <BaseModal
          title="Now for something even more challenging..."
          isOpen={isStatsModalOpen}
          handleClose={() => setIsStatsModalOpen(false)}
        >
          <p>We're looking for people to join our Health Department at Hanover.</p>
          <div className="mt-5 sm:mt-6 columns-2 dark:text-white">
            <div>
              <button
                type="button"
                className="mt-2 w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
              >
                See vacancies
              </button>

            </div>
            <button
              type="button"
              className="mt-2 w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
            >
              Share
            </button>
          </div>

        </BaseModal>
        {false &&
          <StatsModal
            isOpen={isStatsModalOpen}
            handleClose={() => setIsStatsModalOpen(false)}
            solution={solution}
            guesses={guesses}
            gameStats={stats}
            isGameLost={isGameLost}
            isGameWon={isGameWon}
            handleShareToClipboard={() => showSuccessAlert(GAME_COPIED_MESSAGE)}
            numberOfGuessesMade={guesses.length}
          />
        }
        <AlertContainer />
      </div>
    </div>

  )
}

export default Wordle
