import React from 'react'

export const Carousel = () => {
    return (
        <section id="slideshow">
            <div class="entire-content">
                <div class="content-carrousel">
                    <figure class="shadow"><img src="/secretaries/dobson.jpg" /></figure>
                    <figure class="shadow"><img src="/secretaries/hancock.jpg" /></figure>
                    <figure class="shadow"><img src="/secretaries/milburn.jpg" /></figure>
                    <figure class="shadow"><img src="/secretaries/bottomley.jpg" /></figure>
                    <figure class="shadow"><img src="/secretaries/lansley.jpg" /></figure>
                    <figure class="shadow"><img src="/secretaries/reid.jpg" /></figure>
                </div>
            </div>
        </section>
    )
}
