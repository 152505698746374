import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useStopwatch } from 'react-timer-hook';

import { Carousel } from "./Carousel";

const X = ({ size = 16, color = "#fff" }) => (<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>);

const Check = ({ size = 16, color = "#fff" }) => (<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="20 6 9 17 4 12"></polyline></svg>);

const items = [
  {
    title: 'Frank Dobson',
    order: 3,
    id: 0
  },
  {
    title: 'Matt Hancock',
    order: 2,
    id: 1
  },
  {
    title: 'Alan Milburn',
    order: 0,
    id: 2
  },
  {
    title: 'Jeremy Hunt',
    order: 5,
    id: 3
  },
  {
    title: 'Virginia Bottomley',
    order: 1,
    id: 4
  },
  {
    title: 'John Reid',
    order: 4,
    id: 5
  },
]


const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};


export default function Order() {
  const [options, setOptions] = useState(items);
  const [answers, setAnswers] = useState(items.map(i => null));
  const [done, setDone] = useState(false);
  const [isWelcomed, setIsWelcomed] = useState(false)

  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: false });

  const onDragEnd = result => {
    const { source, destination } = result;

    console.log(source, destination);
    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      return;
    }


    if (source.droppableId.includes('options-') && destination.droppableId.includes('answers-')) {
      const index = parseInt(source.droppableId.substr(source.droppableId.length - 1));
      const sourceObject = options[index];
      const newOptions = options.map((o, i) => {
        if (i === index) {
          return null
        }

        return o;
      });
      setOptions(newOptions);
      const destIndex = parseInt(destination.droppableId.substr(destination.droppableId.length - 1));
      const newAnswers = answers;
      newAnswers[destIndex] = sourceObject;
      setAnswers(newAnswers);
    }

    if (source.droppableId.includes('answers-') && destination.droppableId.includes('options-')) {
      const index = parseInt(source.droppableId.substr(source.droppableId.length - 1));
      const sourceObject = answers[index];
      setAnswers(answers.map((o, i) => i === index ? null : o));
      const destIndex = parseInt(destination.droppableId.substr(destination.droppableId.length - 1));
      const newOptions = options;
      newOptions[destIndex] = sourceObject;
      setOptions(newOptions);

    }

    if (source.droppableId.includes('answers-') && destination.droppableId.includes('answers-')) {
      console.log('source is answers');
      const sourceIndex = parseInt(source.droppableId.substr(source.droppableId.length - 1));
      const sourceObject = answers[sourceIndex];
      const destIndex = parseInt(destination.droppableId.substr(destination.droppableId.length - 1));

      const newAnswers = answers.map((o, i) => {
        if (i === sourceIndex) {
          return null
        }
        if (i === destIndex) {
          return sourceObject
        }
        return o;
      });

      setAnswers(newAnswers)
    }

  };

  useEffect(() => {
    let correct = true
    answers.map((a, i) => {
      if (!a || a.order !== i) {
        console.log(a)
        correct = false;
      }
    })

    const interval = setInterval(() => {
      if (correct) {
        setDone(true)
        pause();
      }
    }, 500);
    return () => clearInterval(interval);

  })


  if (!isWelcomed) {
    return (
      <div>
        <Carousel />
        <h3 className="mt-16 font-black text-xl uppercase text-white  pb-4">We’re looking for smart, curious people that think differently. <br />We call it <em>UNCOMMON SENSE</em>.
          <br /><span className="text-pink">Take our test to see if you can work with the best.</span></h3>
        <p>
          <button onClick={() => setIsWelcomed(true)} className="btn">Take the test</button>
        </p>
      </div >
    )
  }

  if (done) {
    return (
      <div>
        <img src="https://i.giphy.com/OKJ0gKcwbK26efka5i.gif" alt="" className="w-full mb-4" />
        <h3 className="font-black text-3xl uppercase text-white border-b border-white border-opacity-50 pb-4">Sit down, you've nailed it!</h3>
        <p className="font-serif text-white mb-4 text-2xl pt-4">You clearly take your health policy as seriously as we do.
          <br />
          <br />
          Find out more about what we might have in common on our careers page.</p>
        <p>
          <a target="_blank" rel="noreferrer" href="https://www.hanovercomms.com/vacancies/" className="btn">Careers with Hanover</a>
        </p>
      </div>
    )
  }


  return (
    <div className="w-full text-white">

      <p className="font-serif text-white mb-4 text-2xl pt-4 "><strong>Beat the uncommon sense test:</strong> Drag these Secretaries of State for Health into order of length of service, from shortest to longest.</p>
      <p className="font-serif text-white mb-3 text-2xl pr-4 fixed bottom-0 right-0">{minutes}:{seconds < 10 ? ('0' + seconds.toString()) : seconds}</p>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="grid grid-cols-2 text-center  gap-4 border-b border-white border-opacity-30 mb-4 pb-4">
          {options.map((item, index) => (
            <Droppable droppableId={"options-" + index} key={"option-" + index} isDropDisabled={options[index] !== null} >
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  className="order-item border-opacity-30"

                >
                  {item !== null &&
                    <Draggable
                      key={'option' + item.order}
                      draggableId={item.order.toString()}
                      index={item.id}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="order-item"
                        >
                          {item.title}
                        </div>
                      )}
                    </Draggable>
                  }
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ))}

        </div>

        <div className="w-52 mx-auto">
          {answers.map((item, i) => (
            <Droppable droppableId={"answers-" + i} key={"answers" + i} isDropDisabled={answers[i] !== null} >
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  className="order-item my-2  border-opacity-30 mx-auto"
                >
                  {item !== null &&
                    <Draggable
                      key={'answer' + item.order}
                      draggableId={item.order.toString()}
                      index={item.id}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={`relative order-item ${item.order === i ? 'bg-green-800' : 'bg-red-800'}`}
                        >
                          {item.title}
                          <div className="absolute -left-6 top-1/2 -translate-y-1/2">

                            {item.order === i ? <Check /> : <X />}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  }
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ))}
        </div>

      </DragDropContext>
    </div>
  )
}


// // fake data generator

// // a little function to help us with reordering the result
// const reorder = (list, startIndex, endIndex) => {
//   const result = Array.from(list);
//   const [removed] = result.splice(startIndex, 1);
//   result.splice(endIndex, 0, removed);

//   return result;
// };

// const grid = 8;

// const getItemStyle = (isDragging, draggableStyle, correct) => {


//   return {
//     // some basic styles to make the items look a bit nicer
//     userSelect: "none",

//     // change background colour if dragging
//     background: correct ? "green" : "#F55151",

//     // styles we need to apply on draggables
//     ...draggableStyle
//   }
// };

// const getListStyle = isDraggingOver => ({
//   background: isDraggingOver ? "lightblue" : "lightgrey",
//   padding: grid,
//   width: 250
// });

// class App extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       items,
//       showWelcome: false
//     };
//     this.onDragEnd = this.onDragEnd.bind(this);
//   }

//   onDragEnd(result) {
//     // dropped outside the list
//     if (!result.destination) {
//       return;
//     }

//     const items = reorder(
//       this.state.items,
//       result.source.index,
//       result.destination.index
//     );

//     this.setState({
//       items
//     });
//   }

//   // Normally you would want to split things out into separate components.
//   // But in this example everything is just done in one place for simplicity
//   render() {
//     return (
//       <div>
//         <BaseModal title="Not all secretaries of state are created equal" isOpen={this.state.showWelcome} handleClose={() => this.setState({ showWelcome: false })} >
//           <p>Reorder the ministers from shortest to longest term.</p>
//           <div className="bg-green-500 mt-4 w-full h-14 border-solid border-2 mb-2 flex items-center justify-center mx-0.5 text-4xl font-bold rounded dark:text-white bg-white dark:bg-slate-900 border-slate-200 dark:border-slate-600">
//             Correct answer
//           </div>
//           <div className="bg-red-500 w-full h-14 border-solid border-2 mb-2 flex items-center justify-center mx-0.5 text-4xl font-bold rounded dark:text-white bg-white dark:bg-slate-900 border-slate-200 dark:border-slate-600">
//             Incorrect answer
//           </div>
//         </BaseModal>
//         <DragDropContext onDragEnd={this.onDragEnd}>
//           <p class="text-white text-center text-2xl my-4 ">Reorder the ministers from shortest to longest term.</p>

//           <Droppable droppableId="droppable" className="">
//             {(provided, snapshot) => (
//               <div
//                 {...provided.droppableProps}
//                 ref={provided.innerRef}
//                 className="grid grid-cols-2 gap-4 max-h-screen overflow-hidden w-screen items-center"
//               >
//                 {this.state.items.map((item, index) => (
//                   <Draggable key={item.order.toString()} draggableId={item.order.toString()} index={index}>
//                     {(provided, snapshot) => (
//                       <div
//                         ref={provided.innerRef}
//                         {...provided.draggableProps}
//                         {...provided.dragHandleProps}
//                         style={getItemStyle(
//                           snapshot.isDragging,
//                           provided.draggableProps.style,
//                         )}
//                         className=" w-full h-14 border-solid border-2 mb-2 flex items-center justify-center mx-0.5 text-2xl font-semibold rounded dark:text-white bg-white dark:bg-slate-900 border-slate-200 dark:border-slate-600"
//                       >
//                         {item.title}
//                       </div>
//                     )}
//                   </Draggable>
//                 ))}
//                 {provided.placeholder}
//               </div>
//             )}
//           </Droppable>

//           <div className="grid grid-cols-1 gap-4 px-4 max-h-screen overflow-hidden w-screen items-center">
//             {this.state.items.map((item, index) => (
//               <Droppable droppableId="droppable-2" >
//                 {(provided, snapshot) => (
//                   <div
//                     {...provided.droppableProps}
//                     ref={provided.innerRef}
//                     className="h-14 border border-2 rounded "
//                   >
//                     {provided.placeholder}

//                   </div>
//                 )}
//               </Droppable>
//             ))}
//           </div>

//         </DragDropContext>
//       </div >
//     );
//   }
// }

// export default Order;